import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/less/reset.less'
import Paginate from 'vuejs-paginate'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

router.beforeEach((to, from, next) => {
  let url = window.location.href;
  if (url.includes('?from=')) {
      url = url.replace(/la-passion.cn.+.#/, 'la-passion.cn/#');
      window.location.href = url; // 重定向到正常链接
  }
  next()
})

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.component('paginate', Paginate)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
