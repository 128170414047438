import axios from './axios-instance'
import path, { ENV } from './request-path'

function normalize(url, params = {}, urlOrg) {
  if (ENV === 'local') {
    return Promise.resolve(urlOrg || url)
  }
  return axios.get(url, params)
}

// 新闻分页列表 pageNum=1&pageSize=2 done
export const getNewsList = (params) => normalize(path.newsList, { params: { channelId: 1, ...params } })
// 成功案例分页列表 pageNum=1&pageSize=2 done
export const getSuccessList = (params) => normalize(path.successList, { params })
// 详情页 pageNum=1&pageSize=2 done
export const getDetails = (id) => normalize(`${path.details}/${id}`, {}, path.details)
// 首页 pageNum=1&pageSize=2 done
export const getIndexList = (params) => normalize(path.indexList, { params })
// 其他案例 pageNum=1&pageSize=2&channelId=5&articleId=3 done
export const getOtherList = (params) => normalize(path.otherList, { params })
// 关于 done
export const getAbout = () => normalize(path.about)
// 联系 done
export const getContact = () => normalize(path.contact)
// 加入
export const getJoin = () => normalize(path.join)
// 频道列表
export const getChannelList = () => normalize(path.channelList)
// 分享
export const shareInfo = (params) => normalize(path.shareInfo, { params })
