<template>
  <div class="page-wrap">
    <div class="page-conter" v-if="showPage">
      <page-top :navmenu="navmenu" />
      <router-view :navmenu="navmenu" />
      <page-footer class="page-footer" />
    </div>
  </div>
</template>

<script>
import PageTop from '../components/PageTop'
import PageFooter from '../components/PageFooter'
import { getChannelList } from '@/request/index'

export default {
  components: {
    PageTop,
    PageFooter
  },
  data() {
    return {
      showPage: false,
      navmenu: []
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    async initPage() {
      try {
        const res = await getChannelList()
        if (res.code === 200) {
          res.data.map((item) => {
            if (item.children) {
              item.children = item.children.splice(1)
            }
            return item
          })
          this.navmenu = res.data
          console.log('this.navmenu:', this.navmenu)
          this.showPage = true
        } else {
          throw new Error('') 
        }
      } catch (error){
         console.log('数据错误')
        this.showPage = true
      }
    },
  }
}
</script>

<style scoped lang="less">
.page-wrap {
  width: 100%;
  min-width: auto;
}
.page-conter {
  display: inline-block;
  width: 100%;
  position: relative;
  min-height: 100%;
  padding-bottom: 272px;
  box-sizing: border-box;
}
.page-footer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 320px);
  left: 160px;
}
</style>

<style lang="less">
.web .page-wrap {
  width: 100%;
  min-width: auto;
}
.web .page-conter {
  padding-bottom: 1.3rem;
}
.web .page-footer {
  width: 100%;
  left: 0;
}
</style>