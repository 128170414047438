<template>
  <div id="app">
    <Layout v-if="share" />
  </div>
</template>

<script>
import Layout from '@/pages/layout'
import { shareInfo } from './request'

export default {
  components: {
    Layout
  },
  data() {
    return {
      share: false
    }
  },
  mounted() {
    this.initShare()
  },
  methods: {
    async initShare() {
      const info = await shareInfo({ url: encodeURIComponent(window.location.href.split('#')[0]) })
      window.wx.config({
        debug: false,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage'],
        appId: info.appId,
        timestamp: String(info.timestamp),
        nonceStr: info.nonceStr,
        signature: info.signature
      });
      this.share = true
      window.wx.error((err) => {
        console.log('err:', err)
      })
    },
  }
}
</script>
