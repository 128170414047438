import {
    newsList,
    successList,
    indexList,
    details,
    otherList,
    about,
    contact,
    join,
    channelList,
    shareInfo,
} from './mock'

// export const ENV = 'local'
export const ENV = 'test'
// const baseUrl = '//test.lpbs.sttxx.com'
const baseUrl = '//admin.la-passion.cn'
// const baseUrl = ''

const pathList = {
    local: {
        newsList,
        successList,
        indexList,
        details,
        otherList,
        about,
        contact,
        join,
        channelList,
        shareInfo,
    },
    test: {
        // 新闻分页列表 ?pageNum=1&pageSize=2&channelId=2
        newsList: `${baseUrl}/api/article/list`,
        // 成功案例分页列表 ?pageNum=1&pageSize=2&channelId=1
        successList: `${baseUrl}/api/article/list`,
        // 首页 ?pageNum=1&pageSize=2
        indexList: `${baseUrl}/api/article/list`,
        // 详情页 /id
        details: `${baseUrl}/api/article`,
        // 其他案例 ?pageNum=1&pageSize=2&channelId=5&articleId=3
        otherList: `${baseUrl}/api/article/related`,
        // 关于 
        about: `${baseUrl}/api/page/about`,
        // 联系
        contact: `${baseUrl}/api/page/contact`,
        // 加入
        join: `${baseUrl}/api/page/join`,
        // 频道列表
        channelList: `${baseUrl}/api/article/channel/list`,
        // 分享信息
        shareInfo: `${baseUrl}/api/wx/jsapi/wx8d5bf2c1edd5f9cc/getJsapiSignature`
    }
}

export default pathList[ENV]
