import axios from 'axios';

const instance = axios.create({
    timeout: 10000
})

instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.message.includes('timeout')) {
            console.log('请求超时，请重试')
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)

export default instance;