<template>
  <div class="footer-wrap" :class="addClass === true ? 'footer-fixed' : ''">
    <span class="footer-left">Copyright © 2021 兰普博森(北京)国际文化传媒有限公司 版权所有</span>
    <span class="footer-left footer-underline">hr@la-passion.cn</span>
    <span class="footer-left footer-underline">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer noopener">京ICP备15024086号-1</a>
    </span>
    <!-- <img class="footer-right footer-right_img" src="~@/assets/img/wechat@2x.png" alt="">
    <img class="footer-right footer-right_img" src="~@/assets/img/weibo@2x.png" alt=""> -->
  </div>
</template>

<script>
export default {
  props: {
    addClass: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      a: 1
    }
  }
}
</script>

<style lang="less">
.footer-wrap {
  height: 79px;
  margin-top: 124px;
  overflow: hidden;
}
.footer-left {
  margin-right: 43px;
  line-height: 79px;
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  float: left;
}
.footer-right {
  float: right;
  margin-left: 31px;
  line-height: 79px;
}
.footer-right_img {
  margin-top: 30px;
  width: 23px;
  height: 19px;
  vertical-align: middle;
}
.footer-fixed {
  position: absolute;
  bottom: 0;
  left: 160px;
  .footer-left {
    font-size: 10px;
    color: #FFFFFF;
    font-weight: 400;
  }
}
</style>

<style lang="less">
.web {
  .footer-fixed {
    display: none;
  }
  .footer-wrap {
    width: 100%;
    height: 1.3rem;
    margin-top: 0.64rem;
    box-sizing: border-box;
    padding: 0 0.15rem;
    padding-top: 0.8rem;
  }
  .footer-right_img {
    display: none;
  }
  .footer-left {
    line-height: 0.16rem;
    font-size: 0.12rem;
    margin-right: 1rem;
  }
  .footer-underline {
    text-decoration: underline;
    margin-right: 0.2rem;
  }
}
</style>
<style lang="less">
  a {
      color: inherit;
      text-decoration: none;
      -webkit-transition: color .25s;
      transition: color .25s;
  }
</style>
