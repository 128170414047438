export const newsList = {
  "code": 200,
  "data": {
      "rows": [
          {
              "articleId": 42,
              "articleTitle": "Nvrher",
              "summary": "NvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrherNvrher",
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-09 23:40:47",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": "IypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcerIypgcer",
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          },
          {
              "articleId": 115,
              "articleTitle": "Iypgcer",
              "summary": null,
              "channelId": 4,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-05 23:41:08",
              "channelName": "品牌",
              "channelIds": null,
              "content": "品牌品牌品牌品牌11品牌22"
          }
      ],
      "total": 152
  }
}

export const successList = {
  "code": 200,
  "data": {
      "rows": [
          {
              "articleId": 5,
              "articleTitle": "Snz",
              "summary": "asdasdsavghbjnikkoiutcgvybunijokbvcgvhjbknlm;vctgvhybjunkimlolnbvcgvhjbknlmnbvhbjknlm",
              "channelId": 1,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "成功案例",
              "channelIds": null,
              "content": "品牌品牌品牌品牌33品牌22"
          },
          {
              "articleId": 10,
              "articleTitle": "Uou",
              "summary": null,
              "channelId": 1,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "3",
              "outerChainLink": null,
              "createTime": "2021-12-01 20:24:13",
              "channelName": "成功案例",
              "channelIds": null,
              "content": "品牌品牌品牌品牌44品牌22"
          },
          {
            "articleId": 5,
            "articleTitle": "Snz",
            "summary": "asdasdsavghbjnikkoiutcgvybunijokbvcgvhjbknlm;vctgvhybjunkimlolnbvcgvhjbknlmnbvhbjknlm",
            "channelId": 1,
            "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
            "orderNum": 0,
            "articleType": "2",
            "outerChainLink": null,
            "createTime": "2021-12-08 20:24:13",
            "channelName": "成功案例",
            "channelIds": null,
            "content": "品牌品牌品牌品牌33品牌22"
        },
        {
            "articleId": 5,
            "articleTitle": "Snz",
            "summary": "asdasdsavghbjnikkoiutcgvybunijokbvcgvhjbknlm;vctgvhybjunkimlolnbvcgvhjbknlmnbvhbjknlm",
            "channelId": 1,
            "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
            "orderNum": 0,
            "articleType": "2",
            "outerChainLink": null,
            "createTime": "2021-12-08 20:24:13",
            "channelName": "成功案例",
            "channelIds": null,
            "content": "品牌品牌品牌品牌33品牌22"
        },
        {
            "articleId": 5,
            "articleTitle": "SSnzSnzSnzSnzSnzSnzSnzSnzSnzSnzSnzSnzSnzSnzSnznz",
            "summary": "asdasdsavghbjnikkoiutcgvybunijokbvcgvhjbknlm;vctgvhybjunkimlolnbvcgvhjbknlmnbvhbjknlm",
            "channelId": 1,
            "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
            "orderNum": 0,
            "articleType": "2",
            "outerChainLink": null,
            "createTime": "2021-12-08 20:24:13",
            "channelName": "成功案例",
            "channelIds": null,
            "content": "品牌品牌品牌品牌33品牌22"
        },
        {
            "articleId": 5,
            "articleTitle": "Snz",
            "summary": "asdasdsavghbjnikkoiutcgvybunijokbvcgvhjbknlm;vctgvhybjunkimlolnbvcgvhjbknlmnbvhbjknlm",
            "channelId": 1,
            "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
            "orderNum": 0,
            "articleType": "2",
            "outerChainLink": null,
            "createTime": "2021-12-08 20:24:13",
            "channelName": "成功案例",
            "channelIds": null,
            "content": "品牌品牌品牌品牌33品牌22"
        },
      ],
      "total": 27
  }
}

export const indexList = {
  "code": 200,
  "data": {
      "rows": [
          {
              "articleId": 138,
              "articleTitle": "Urph",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-11-28 20:24:19",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌55品牌22",
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          },
          {
              "articleId": 55,
              "articleTitle": "Ctps",
              "summary": null,
              "channelId": 3,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "2",
              "outerChainLink": null,
              "createTime": "2021-12-08 20:24:13",
              "channelName": "电视",
              "channelIds": null,
              "content": "品牌品牌品牌品牌66品牌22"
          }
      ],
      "total": 181
  }
}

export const details = {
  "code": 200,
  "data": {
    "articleId": 5,
    "articleTitle": "Snz",
    "summary": null,
    "channelId": 1,
    "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
    "orderNum": 0,
    "articleType": "2",
    "outerChainLink": null,
    "createTime": "2021-12-08 20:24:13",
    "channelName": "成功案例",
    "channelIds": null,
    "content": "hhhh哈哈哈哈3333333222"
  }
}

export const otherList = {
  "code": 200,
  "data": {
      "rows": [
          {
              "articleId": 6,
              "articleTitle": "Hfskze",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "1",
              "outerChainLink": null,
              "createTime": "2021-12-06 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          },
          {
              "articleId": 13,
              "articleTitle": "Fhbuxj",
              "summary": null,
              "channelId": 5,
              "imgUrlList": "http://imges.cycares.com/1a12603f-f8aa-4ce5-8263-3c9192e89509?imageView2/2/w/378/h/212/q/100",
              "orderNum": 0,
              "articleType": "4",
              "outerChainLink": null,
              "createTime": "2021-12-02 20:24:13",
              "channelName": "视频",
              "channelIds": null,
              "content": "视频",
          }
      ],
      "total": 14
  }
}

export const about = {
  "code": 200,
  "data": {
      "createTime": "2021-12-08 20:24:13",
      "content": "hhhh哈哈哈哈3333333222"
  }
}

export const contact = {
  "code": 200,
  "data": {
      "createTime": "2021-12-06 20:24:13",
      "content": "123123123123123123123"
  }
}

export const join = {
  "code": 200,
  "data": {
      "createTime": "2021-11-30 20:24:13",
      "content": "23333333333"
  }
}

export const channelList = {
  "code": 200,
  "data": [
      {
          "id": "1",
          "name": "成功案例",
          "link": "successNews",
          "parentId": "0",
          "hasParent": false,
          "hasChild": true,
          "children": [
            {
                "id": "2",
                "name": "全部",
                "children": null,
                "link": "successNews",
                "parentId": "2",
                "hasParent": true,
                "hasChild": false
            },
            {
                "id": "3",
                "name": "电视",
                "children": null,
                "link": "successNews",
                "parentId": "2",
                "hasParent": true,
                "hasChild": false
            },
            {
                "id": "4",
                "name": "品牌",
                "children": null,
                "link": "successNews",
                "parentId": "2",
                "hasParent": true,
                "hasChild": false
            },
            {
                "id": "5",
                "name": "视频",
                "children": null,
                "link": "successNews",
                "parentId": "2",
                "hasParent": true,
                "hasChild": false
            },
          ],
      },
      {
        "id": "6",
        "name": "关于我们",
        "link": "about",
        "parentId": "0",
        "hasParent": false,
        "hasChild": false,
      },
      {
        "id": "7",
        "name": "加入我们",
        "link": "joinus",
        "parentId": "0",
        "hasParent": false,
        "hasChild": false,
      },
      {
        "id": "8",
        "name": "新闻中心",
        "link": "news",
        "parentId": "0",
        "hasParent": false,
        "hasChild": false,
      },
      {
        "id": "9",
        "name": "联系方式",
        "link": "connection",
        "parentId": "0",
        "hasParent": false,
        "hasChild": false,
      }
  ]
}

export const shareInfo = {
    "code": 200,
    "data": {
        "appId": "wx8d5bf2c1edd5f9cc",
        "nonceStr": "e7pSqI4WziAPqmPJ",
        "timestamp": 1654781514,
        "url": "https://www.baidu.com",
        "signature": "ec1a22664c54be0229628013acc7f7235fd4cdaa"
    }
}
