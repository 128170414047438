import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {undefined
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: '首页',
  //   component: () => import(/* webpackChunkName: "index" */ '../pages/index/index.vue')
  // },
  {
    path: '/',
    name: '首页',
    component: () => import(/* webpackChunkName: "index" */ '../pages/index-channel/index.vue')
  },
  {
    path: '/about',
    name: '关于',
    component: () => import(/* webpackChunkName: "index" */ '../pages/about/index.vue')
  },
  {
    path: '/joinus',
    name: '加入我们',
    component: () => import(/* webpackChunkName: "index" */ '../pages/join/index.vue')
  },
  {
    path: '/news',
    name: '新闻中心',
    component: () => import(/* webpackChunkName: "index" */ '../pages/news/index.vue')
  },
  {
    path: '/connection',
    name: '联系我们',
    component: () => import(/* webpackChunkName: "index" */ '../pages/connection/index.vue')
  },
  {
    path: '/detail',
    name: '案例详情',
    component: () => import(/* webpackChunkName: "index" */ '../pages/detail/index.vue')
  },
  {
    path: '/successNews',
    name: '成功案例',
    component: () => import(/* webpackChunkName: "index" */ '../pages/success-news/index.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
