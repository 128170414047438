<template>
  <div>
    <div class="top-wrap" @touchmove.prevent @mousewheel.prevent>
      <img class="top-logo" @click="backHome" src="@/assets/img/logo@2x.png" alt="">
      <slot></slot>
      <div :class="['top-menu', showMenu ? 'active' : '']">
        <div class="top-click_area" @click="openMenu"><i></i><i></i></div>
        <div class="top-menu-wrap">
          <ul class="top-menu_ul-pc">
            <template v-for="item in navmenu">
              <li :key="item.id">
                <p @click="jump(item.link)">{{ item.name }}</p>
              </li>
            </template>
          </ul>
          <ul class="top-menu_ul">
            <template v-for="item in navmenu">
              <li :key="item.id">
                <p @click="jump(item.link)">{{ item.name }}</p>
              </li>
            </template>
          </ul>
          <pageFooter :addClass='true' />
        </div>
      </div>
    </div>
    <div class="top-bottom"></div>
  </div>
</template>

<script>
import pageFooter from './PageFooter.vue'

export default {
  props: {
    navmenu: {
      type: Array,
    }
  },
  components: {
    pageFooter
  },
  data() {
    return {
      showMenu: false,
      showSubMenu: false
    }
  },
  methods: {
    openMenu() {
      this.showMenu = !this.showMenu
    },
    openSubMenu() {
      this.showSubMenu = !this.showSubMenu
    },
    backHome() {
      this.showMenu = false
      if (this.showSubMenu === true) {
        this.showSubMenu = false
      }
      this.$router.push({ path: '/'})
    },
    jump(url) {
      this.showMenu = false
      if (this.showSubMenu === true) {
        this.showSubMenu = false
      }
      if (url === 'successNews') {
        this.$router.push({ path: url, query: {tab: 0}})
      } else {
        this.$router.push({ path: url })
      }
    }
  }
}
</script>

<style lang="less">
.top-wrap {
  position: relative;
  width: 100%;
  padding: 0 160px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 86px;
  background: #FFFFFF;
  box-shadow: 0px 3px 4px 3px rgba(228,228,228,0.5);
  margin-bottom: 46px;
}
.top-bottom {
  width: 100%;
  height: 86px;
  display: none;
}
.top-logo {
  position: relative;
  width: 86px;
  height: 86px;
  float: left;
  z-index: 1001;
}
.top-click_area {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1001;
}
.top-menu {
  position: relative;
  float: right;
  width: 26px;
  height: 100%;
  top: 0;
  i {
    display: block;
    width: 100%;
    position: absolute;
    right: 0;
    background: #D80716;
    height: 2px;
    border-radius: 1px;
    transform: rotate(0);
    &:nth-child(1) {
      top: 38px;
    }
    &:nth-child(2) {
      top: 48px;
    }
  }
  &.active {
    i {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 43px;
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        top: 43px;
      }
    }
    .top-menu-wrap {
      display: block;
      transition: 0.3s;
      opacity: 1;
    }
  }
}
.top-menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 1000;
  display: none;
  opacity: 0;
  user-select: none;
  .top-menu_ul-pc {
    width: 569px;
    margin-right: 180px;
    display: inline-block;
    height: 86px;
    float: right;
    li {
      float: left;
      font-size: 17px;
      line-height: 86px;
      color: #FFFFFF;
      text-align: center;
      font-weight: 600;
      margin-right: 32px;
      font-weight: 500;
      &:hover {
        color: #D80716;
      }
    }
  }
  .top-menu_ul {
    width: 1120px;
    margin: 0 auto;
    margin-top: 169px;
    display: none;
  }
  li {
    position: relative;
    font-size: 33px;
    color: #FFFFFF;
    font-weight: 600;
    margin-bottom: 18px;
    cursor: pointer;
    &.top-submenu {
      &.active {
        ul {
          display: block;
        }
        .top-submenu_icon {
          transform: rotate(180deg);
        }
      }
      ul {
        display: none;
        margin-left: 164px;
        li {
          color: #FFFFFF;
          font-weight: 400;
          font-size: 17px;
        }
      }
    }
    .top-submenu_icon {
      position: absolute;
      left: 164px;
      top: 10px;
      font-size: 21px;
      transform: rotate(0);
      transition: 0.3s;
    }
  }
}
</style>

<style lang="less">
.web {
  .top-wrap {
    height: 0.56rem;
    box-sizing: border-box;
    padding: 0 0.15rem;
    width: 100%;
    margin-bottom: 0.44rem;
  }
  .top-bottom {
    width: 100%;
    height: 0.56rem;
    display: none;
  }
  .top-logo {
    width: 0.56rem;
    height: 0.56rem;
  }
  .top-menu {
    width: 0.21rem;
    i:nth-child(1) {
      top: 0.22rem;
    }
    i:nth-child(2) {
      top: 0.3rem;
    }
    &.active {
      width: 0.28rem;
      i {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0.27rem;
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
          top: 0.27rem;
        }
      }
    }
  }
  .top-menu-wrap {
    box-sizing: border-box;
    padding: 0 0.15rem;
    .top-menu_ul {
      display: block;
      width: 100%;
      margin-top: 1.56rem;
      margin-left: 0.29rem;
    }
    .top-menu_ul-pc {
      display: none;
    }
    li {
      font-size: 0.18rem;
      margin-bottom: 0.26rem;
      &:hover {
        color: #D80716;
      }
      .top-submenu_icon {
        left: 1.2rem;
        font-size: 0.2rem;
        top: 0.03rem;
      }
      &.top-submenu ul {
        margin-left: 1.2rem;
        margin-top: 0.17rem;
        li {
          font-size: 0.16rem;
          margin-bottom: 0.2rem;
        }
      }
    }
  }
}
</style>